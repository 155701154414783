<template>
  <main>
  	<div id="slide"></div>
  	<div class="videos_wrapper">

  		<div id="#container" class="fullpage-wp">
			  
  			<div v-if="this.bottom === true" class="next"><img @click="moveNext" src="../assets/images/next.png"></div>

  			<div class="videos">
					<div class="video">
						<div class="campagne">
							<h2 v-html="$CD('FOODISTAR_TITRE')"/>
							<p v-html="$CD('FOODISTAR_TEXTE')"/>
							<br />
							<br />
							<br />
						</div>
					</div>
  				<div class="video  one">
  					<div class="video-left">
  						<youtube :video-id="`${id_video_1}`"></youtube>
  						<br />

  					</div>
  					<div class="video-right">
  						<h2 v-html="$CD('FOODISTAR_VIDEO_1')" />
  						<p v-html="$CD('FOODISTAR_VIDEO_LEGENDE_1')" />
  					</div>
  				</div>

  				<div class="video" id="#videos2">
  					<div class="video-left">
  						<youtube :video-id="`${id_video_2}`"></youtube>
  						<br />

  					</div>
  					<div class="video-right">
  						<h2 v-html="$CD('FOODISTAR_VIDEO_2')" />
  						<p v-html="$CD('FOODISTAR_VIDEO_LEGENDE_2')" />
  					</div>
  				</div>
  				<div class="video">
  					<div class="video-left">
  						<youtube :video-id="`${id_video_3}`"></youtube>
  						<br />

  					</div>
  					<div class="video-right">
  						<h2 v-html="$CD('FOODISTAR_VIDEO_3')" />
  						<p v-html="$CD('FOODISTAR_VIDEO_LEGENDE_3')" />
  					</div>
  				</div>
  				<div class="video">
  					<div class="video-left">
  						<youtube :video-id="`${id_video_4}`"></youtube>
  						<br />

  					</div>
  					<div class="video-right">
  						<h2 v-html="$CD('FOODISTAR_VIDEO_4')" />
  						<p v-html="$CD('FOODISTAR_VIDEO_LEGENDE_4')" />
  					</div>
  				</div>

				  <div>
				  <router-link tag="button" to="/quiz" class="button2">Découvrez votre profil foodista !</router-link >
	
				  </div>

				<div class="contact-presse2 white-bg-p-5" style="color: black;">
					Relations presse : <a style="color: black;text-decoration:underline;" href="mailto:presse.vinsociete@comfluence.fr">presse.vinsociete@comfluence.fr</a>
					<br />
					Twitter : <a style="color: black;text-decoration:underline;" href="http://twitter.com/ValerieFuchsCom" target="_blank">@ValerieFuchsCom</a>
					<br />
					<router-link style="color: black;text-decoration:underline;" to="/mentions">Mentions légales</router-link>
				</div>  
  			</div>
  		</div>
  	</div>
  </main>
</template>  
<script>
export default {

  name: 'Videos',
  data() {
    return {        
		bottom: true,
		id_video_1 : this.$CD('FOODISTAR_ID_YOUTUBE_1'),
		id_video_2 : this.$CD('FOODISTAR_ID_YOUTUBE_2'),
		id_video_3 : this.$CD('FOODISTAR_ID_YOUTUBE_3'),
		id_video_4 : this.$CD('FOODISTAR_ID_YOUTUBE_4'),				
    }
  },
  mounted() {
        var element = document.querySelector('.fullpage-wp');
        var btbottom = document.querySelector('.next');
        element.onscroll = (ev) => {
			console.log(0)
            if( (element.scrollTop+100) >= element.scrollHeight - element.offsetHeight) btbottom.style.display = 'none';
            if( (element.scrollTop+1000) <= element.scrollHeight - element.offsetHeight) btbottom.style.display = 'block';            
        };
  },
  methods:{  
    moveNext(){
     var container = this.$el.querySelector(".fullpage-wp");
     container.scrollTop = container.scrollHeight;
    }
  }
}
</script> 
<style lang="scss">
@import "../scss/_variables.scss";

.videos_wrapper {
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
	justify-content: center;
	background-image: url('../assets/images/fond-18.jpg');
	background-size: cover;
	background-position: 50% 100%;
	background-repeat: no-repeat;
	z-index: 1;
	overflow: auto;

	a {
		text-decoration: none;
		color:white;
	}

	.videos {
		padding-top: 0px !important;
		padding-bottom: 50px !important;

		@media screen and (max-width: 768px) {
			// margin-top: -90px;
		}	

	}

	.video {
		width: 97%;
		margin: 0 auto;
		margin-top: 19px;
		display: flex;
		justify-content: center;
		max-width: 1600px !important;
	    flex-wrap: wrap;
		flex-direction: column-reverse;
		@media screen and (max-width: 1100px) {
		   margin-top: 30px;
		}

		@media screen and (max-width: 768px) {
			margin-top: 0px;
		}		

	}

	.one {
		@media screen and (max-width: 768px) {
			margin-top: -50px !important;
		}	
	}

	.video-left {
		width: 90%;
		 max-width:690px;
		margin:0 auto;

		iframe {
			width: 100%;
			height: 365px;
			border: 0;
			margin-bottom:40px;

			@media screen and (max-width: 768px) {
				margin-bottom: 0px;
			}	
		}

		p {
            font-family: "acumin-pro", sans-serif;
            font-weight: 500;       
			font-size: 20px;
			line-height: 22px;
			text-align: justify;
			color: white;
			margin-top:-10px;
		}

		@media screen and (max-width: 1500px) {
			iframe {
				height: 450px;
			}
		}

		@media screen and (max-width: 1100px) {
			iframe {
				height: 350px;
			}
		}

		@media screen and (max-width: 1100px) {
			width: 90%;
			margin: 0 auto;
			text-align: center;

			p {
				text-align: center;
			}
		}

		@media screen and (max-width: 768px) {
			margin-bottom:0px;
			iframe {
				height: 180px;
			}

			p {
				font-size: 15px;
			    line-height: 19px;
			}
		}			
	}

	.video-right {
		width: 90%;
		color: white;
		margin:0 auto;
		margin-top:-50px;
		 max-width:690px;

		h2 {
			font-family: $font-extra;
			font-weight: bold;
			font-style: italic;
			font-size: 58px;
			line-height: 47px;
			margin-bottom: 25px;
			width:100%;
			text-align : center;
	
		}

		p {
            font-family: "acumin-pro", sans-serif;
            font-weight: 500;    
			font-size: 20px;
			line-height: 25px;
			text-align: justify;
			margin-bottom:25px;
						text-align : center;
						font-weight:bold;
						text-decoration:underline;
		}

		@media screen and (max-width: 1500px) {
			h2 {
				font-size: 48px;
				line-height: 52px;
			}

			p {
				font-size: 17px;
			}
		}

		@media screen and (max-width: 1230px) {
			h2 {
				font-size: 40px;
				line-height: 42px;
			}
		}

		@media screen and (max-width: 1100px) {
			width: 90%;
			text-align: center;
			margin: 0 auto;
		//	margin-bottom: 30px;

			p {
				text-align: center;
			}
		}

		@media screen and (max-width: 768px) {
			h2 {
				font-size: 35px;
				line-height: 37px;
			}
			
			p {
				font-size: 18.5px;
				line-height: 24px;	
			}
		}		
	}
}
</style>